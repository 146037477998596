<template>
    <div class="data-import view content-padding">
        <BaseCard padding="30px">
            <SvgLoader :loading="loading" />
            <div v-if="stage == 0" class="data-field">
                <h5>
                    {{ labels.select_json_with_users }}
                </h5>
                <FormulateInput type="file" />
                <Button variant="btn-purple">
                    {{ labels.import }}
                </Button>
            </div>
        </BaseCard>
    </div>
</template>
  
<script>  
  import { mapActions, mapGetters } from "vuex";
  
  export default {
    name: 'DataImport',
    components: {
      SvgLoader: () => import('@/components/Preloaders/SvgLoader'),
      BaseCard: () => import('@/components/Cards/BaseCard.vue'),
      Button: () => import('@/components/Buttons/Button.vue')
    },
    data() {
      return {
        loading: false,
        stage: 0
      };
    },
    mounted() {

    },
    computed: {
      ...mapGetters('helper', ['labels']),
    },
    methods: {
      ...mapActions('notification', ['addNotification'])
    }
  };
</script>
  
<style lang="scss" scoped>
    .data {
        &-field {
            display: flex;
            align-items: center;
            flex-direction: column;

            .formulate-input {
                width: 250px;
            }
        }
    }
</style>
  